<template>
  <q-page class="flex flex-center">
    <div class="column full-height full-width flex flex-center">
      <!-- Info box -->
      <q-separator/>

      <div class="flex-center full-width bg-brown-6 text-white title-box">
        <div class="text-h3">
          Dr.med. Carl Fanzun
        </div>
        <q-separator color="white" size="2px"/>
        <div class="text-h4" style="margin-top: 10px">
          Facharzt FMH für Psychiatrie und Psychotherapie
        </div>
      </div>

      <q-separator/>



      <!-- Contact info -->
      <div class="text-h5" style="padding: 10px; margin-top: 30px">
        Kontakt
      </div>
      <q-card class="flex-center flex" style="width: 350px; padding: 30px 10px; text-align: center">
        <div class="text-h7">
          <div class="row">
            <q-icon
                name="home"
                size="24px"
                color="grey-8"
            />
            <div style="margin-left: 5px">
              Dr. med. Carl Fanzun
              <br/>
              Schwefelstrasse 25
              <br/>
              9490 Vaduz
              <br/>
            </div>
          </div>

          <div style="margin-top: 5px">
            <q-icon
                name="phone"
                size="24px"
                color="grey-8"
            />
            +423 230 15 00
          </div>
        </div>
      </q-card>


      <!-- Partners -->
      <div class="text-h5" style="padding: 10px; margin-top: 30px">
        Partner
      </div>
       <q-card class="overflow-hidden">
          <a
            href="https://www.zentiva.ch/de-ch"
            target="_blank"
          >
            <img
              style="width: 350px"
              alt="Zentiva Logo"
              src="../assets/zentiva.png"
            >
          </a>
        </q-card>

      <!-- Location -->
      <div class="text-h5" style="padding: 10px; margin-top: 30px">
        Standort
      </div>
      <div class="full-width" style="height: 550px">

        <iframe
            width="100%"
            height="100%"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            id="gmap_canvas"
            src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Schwefelstrasse%2025%20Vaduz+(Praxis%20Dr.%20med.%20Carl%20Fanzun)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        </iframe>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'MainPage'
}
</script>


<style>
  .title-box{
    padding: 80px 20%;
    opacity: 0.75;
  }
</style>
