<template>
  <q-layout view="hhh lpr fFf" class="main">
    <q-page-container>
      <MainPage />
    </q-page-container>
    <q-footer elevated class="bg-brown-6">
      <div class="row justify-between" style="padding: 10px">
        <div >
          © {{ new Date().getFullYear().toString() }} Dr. med. Carl Fanzun
        </div>
        <a href="mailto:info@fanzun.li">
          info@fanzun.li
        </a>
      </div>
    </q-footer>
  </q-layout>
</template>

<script>
import MainPage from "@/components/MainPage.vue";

export default {
  name: 'LayoutDefault',

  components: {
    MainPage
  },
  created () {
    document.title = "Dr. med. Carl Fanzun";
  }
}
</script>

<style>
  a {
    color: white;
    text-decoration: none;
  }

  .main{
    background-image: url("assets/background.png");
  }
</style>
